<template>
    <v-card>
        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_destinations')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                        >
                            <v-autocomplete v-model="destinationData.from"
                                            :label="$t('from')"
                                            required
                                            dense
                                            outlined
                                            :items="selectData.destinations"
                                            item-text="value"
                                            item-value="id"
                                            @change="selectedData"
                            ></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                        >
                            <v-autocomplete v-model="destinationData.to"
                                            :label="$t('to')"
                                            required
                                            dense
                                            outlined
                                            :items="destinations"
                                            item-text="value"
                                            item-value="value"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="rgb(157,25,25)" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AirDestinationEditForm",
        computed: {
            destinations() {
                return this.selectData.destinations.filter(destination => destination.value !== this.selectData.selectedValue);
            }
        },
        props: ['editItemIndex'],
        data() {
            return {
                destinationData: {},
                selectData: {
                    destinations: [],
                    selectedValue: null
                },
                message: {
                    status: true,
                    text: 'true',
                }
            }
        },
        methods: {
            dialogueClose() {
                this.destinationData = {}
                this.$emit('dialogueClose')
            },
            formSubmit() {
                axios.post('Aviation/UpdateAirDestinationAsync', this.destinationData)
                    .then(() => {
                        this.message.status = true
                        this.message.text = 'Destination is Successfully Updated'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
                    .catch(() => {
                        this.message.status = false
                        this.message.text = 'Error Updating Destination, Please Contact Admin'
                        this.$emit('formResponse', this.message)
                        this.dialogueClose()
                    })
            },
            async loadData() {
                const destinations = await axios.get('StaticValues/DDLCommonStaticValuesListAsync/100')
                this.selectData.destinations = destinations.data
            },
            selectedData(value) {
                this.selectData.selectedValue = value
            },
            async getEditData() {
                console.log(this.editItemIndex)
                const destinationData = await axios.get('Aviation/GetAirDestinationByIDAsync/' + this.editItemIndex)
                this.destinationData = destinationData.data
                console.log('data:', this.destinationData)
            },
        },
        mounted() {
            this.loadData()
            this.getEditData()
        }
    }
</script>

<style scoped>

</style>